// src/components/ItemsRouting.js
class ItemsRouting {
    static getRouting() {
     const routes ={
        Home:"/",
        About:"/about",
        Services:"/services",
        Contact:"/contact",
        News:"/news",
     }
     return routes;
    }
    static getAuthRouting() {
     const routes ={
        SignIn:"/auth/signin",
        SignUp:"/auth/signup",
        Verification:"/users/:id/verify/:token",
        Forgot:"/auth/forgot",
        Reset:"/users/:id/reset-password/:token",
     }
     return routes;
    }
  }
  
  export default ItemsRouting;
  